"use client";

import { FC } from "react";
import styles from "./app-footer.module.scss";
import { AppStoreMiniIcon, GooglePlayMiniIcon, MastercardIcon, MirIcon, RustoreMiniIcon, VisaIcon } from "../icon";
import { AccordionFooter } from "./accordion-footer/accordion-footer";
import { FooterForm } from "./footer-form/footer-form";
interface IAppFooter {
  customClassName?: string;
}
const AppFooter: FC<IAppFooter> = props => {
  const {
    customClassName = ""
  } = props;
  return <footer className={`${styles.footer} ${customClassName}`} data-sentry-component="AppFooter" data-sentry-source-file="app-footer.tsx">
      <div className="container">
        <div className={styles.footerWrap}>
          <AccordionFooter data-sentry-element="AccordionFooter" data-sentry-source-file="app-footer.tsx" />
          <div className={styles.footerRight}>
            <div className={styles.footerFormWrap}>
              <h3 className={styles.footerTitle}>
                Новости и акции только для своих
              </h3>
              <FooterForm data-sentry-element="FooterForm" data-sentry-source-file="app-footer.tsx" />
            </div>
            <ul className={styles.footerPayList}>
              <li className={styles.footerPayItem}>
                <VisaIcon data-sentry-element="VisaIcon" data-sentry-source-file="app-footer.tsx" />
              </li>
              <li className={styles.footerPayItem}>
                <MirIcon data-sentry-element="MirIcon" data-sentry-source-file="app-footer.tsx" />
              </li>
              <li className={styles.footerPayItem}>
                <MastercardIcon data-sentry-element="MastercardIcon" data-sentry-source-file="app-footer.tsx" />
              </li>
            </ul>
            <div className={styles.footerDownload}>
              <ul className={styles.footerDownloadList}>
                <li className={styles.footerDownloadItem}>
                  <a href="https://apps.apple.com/app/%D0%BE%D1%85%D0%BE%D1%82%D0%B0%D0%BA%D1%82%D0%B8%D0%B2-%D0%BE%D1%85%D0%BE%D1%82%D0%B0-%D1%80%D1%8B%D0%B1%D0%B0%D0%BB%D0%BA%D0%B0/id6502176237" className={styles.footerDownloadLink} target="_blank" rel="noopener nofollow">
                    <AppStoreMiniIcon className={styles.icon} data-sentry-element="AppStoreMiniIcon" data-sentry-source-file="app-footer.tsx" />
                  </a>
                </li>
                <li className={styles.footerDownloadItem}>
                  <a href="https://play.google.com/store/apps/details?id=comm.ohotaktiv" className={styles.footerDownloadLink} target="_blank" rel="noopener nofollow">
                    <GooglePlayMiniIcon className={styles.icon} data-sentry-element="GooglePlayMiniIcon" data-sentry-source-file="app-footer.tsx" />
                  </a>
                </li>
                <li className={styles.footerDownloadItem}>
                  <a href="https://rustore.ru/catalog/app/com.ohotaktiv" className={styles.footerDownloadLink} target="_blank" rel="noopener nofollow">
                    <RustoreMiniIcon className={styles.icon} data-sentry-element="RustoreMiniIcon" data-sentry-source-file="app-footer.tsx" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>;
};
export default AppFooter;