import Modal from "@/components/modal/modal";
import { FC, createRef, useState } from "react";
import styles from "./callback-popup.module.scss";
import InputComponent from "@/components/input/input";
import TelInput from "@/components/tel-input/tel-input";
import { postCallback } from "@/utils/api/postCallback";
import { phoneValidate } from "@/utils/functions/phoneValidate";
import { callMessage } from "@/utils/functions/callMessage";
import Link from "next/link";
import { googleSendCallback } from "@/utils/api/google-analytic";
interface ICallBackPopup {
  isOpen: boolean;
  onClose: (arg: boolean) => void;
}
export const CallbackPopup: FC<ICallBackPopup> = (props: ICallBackPopup) => {
  const {
    isOpen,
    onClose
  } = props;
  const telInputRef = createRef() as any;
  const [nameInput, setNameInput] = useState("");
  const [buttonDisable, setButtonDisable] = useState(false);
  const callback = () => {
    let phone = telInputRef.current.value;
    // TODO: глянуть шо тут
    if (phone.length === 10 && !!nameInput.length) {
      return callMessage("Успешно!", "Ваша заявка принята. Менеджер с вами свяжется в ближайшее время.", true);
    }
    if (phoneValidate(phone) === false) {
      return;
    }
    let phoneFormat = phone.replace(/\D/g, "");
    phoneFormat = phoneFormat[0] === "7" ? phoneFormat.slice(1) : phoneFormat;
    postCallback(nameInput, phoneFormat).then();
    callMessage("Успешно!", "Ваша заявка принята. Менеджер с вами свяжется в ближайшее время.", true);
    ym(42989679, "reachGoal", "callback");
    setButtonDisable(true);
    onClose(false);
    googleSendCallback();
  };
  return <Modal isOpen={isOpen} title={"Заказать звонок"} onClose={() => onClose(false)} data-sentry-element="Modal" data-sentry-component="CallbackPopup" data-sentry-source-file="callback-popup.tsx">
      <div className={styles.wrap}>
        <form action="#" className={styles.callback_form}>
          <p className={styles.popup_wrap__subtitle}>
            Заполните форму и мы перезвоним вам в ближайшее время
          </p>
          <div className={styles.input_wrap}>
            <InputComponent id={"name-callback-popup"} label={"Введите ваше имя"} className={styles.inputWrap} data-sentry-element="InputComponent" data-sentry-source-file="callback-popup.tsx">
              <input id={"name-callback-popup"} type="text" maxLength={50} className={styles.input_name} placeholder=" " value={nameInput} onChange={e => setNameInput(e.target.value)} />
            </InputComponent>
            <TelInput id={"tel-callback-popup"} placeholder=" " label="Номер телефона" className={styles.input_phone}
          // @ts-ignore: Unreachable code error
          ref={telInputRef} data-sentry-element="TelInput" data-sentry-source-file="callback-popup.tsx" />
          </div>
        </form>
        <button id="submit-button" className={styles.popup_wrap__button} onClick={callback} disabled={buttonDisable}>
          Заказать звонок
        </button>
        <p className={styles.info_text}>
          Нажимая кнопку «Заказать звонок», вы принимаете &nbsp;
          <Link prefetch={false} href="/policy/" onClick={() => onClose(false)} data-sentry-element="Link" data-sentry-source-file="callback-popup.tsx">условия политики персональных данных</Link>
        </p>
        <p className={styles.enter_or}>или</p>
        <div className={styles.or__wrap}>
          <p className={styles.or__text}>Позвоните нам по телефону</p>
          <Link prefetch={false} href="tel:88007008256" className={styles.tel} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="callback-popup.tsx">
            8 800 700 82-56
          </Link>
        </div>
      </div>
    </Modal>;
};