"use client";

import classNames from "classnames";
import styles from "./accordion-footer.module.scss";
import { FC, useEffect, useState } from "react";
import Link from "next/link";
import { DzenIcon, OkIcon, RutubeIcon, TgIcon, VkIcon, YoutubeIcon } from "@/components/icon";
import { ControlledAccordion, useAccordionProvider } from "@szhsin/react-accordion";
import { AccordionItem } from "./accordion-item/accordion-item";
interface IAccordionFooter {}
interface IWindowSize {
  width: number;
  height: number;
}
export const AccordionFooter: FC<IAccordionFooter> = () => {
  const accordionItemWorkClassnames = classNames(`${styles.accordionItem} ${styles.work}`);
  const providerValue = useAccordionProvider({
    allowMultiple: true,
    transition: true,
    transitionTimeout: 250
  });
  const {
    toggleAll
  } = providerValue;
  const [windowSize, setWindowSize] = useState<IWindowSize>({
    width: typeof window !== "undefined" ? window.innerWidth : 0,
    height: typeof window !== "undefined" ? window.innerHeight : 0
  });
  const handleResize = () => setWindowSize({
    width: window.innerWidth,
    height: window.innerHeight
  });
  useEffect(() => {
    windowSize.width > 1023 ? toggleAll(true) : toggleAll(false);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]);
  return <ControlledAccordion className={styles.accordion} providerValue={providerValue} itemScope itemType="https://schema.org/SiteNavigationElement" data-sentry-element="ControlledAccordion" data-sentry-component="AccordionFooter" data-sentry-source-file="accordion-footer.tsx">
      <AccordionItem header="Покупателям" initialEntered data-sentry-element="AccordionItem" data-sentry-source-file="accordion-footer.tsx">
        <ul className={styles.accordionList} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/license/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Правила лицензии
            </Link>
            <meta itemProp="name" content="Правила лицензии" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/pay/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Оплата
            </Link>
            <meta itemProp="name" content="Оплата" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/delivery/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Доставка
            </Link>
            <meta itemProp="name" content="Доставка" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/faq/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              FAQ
            </Link>
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/return/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Обмен и возврат
            </Link>
            <meta itemProp="name" content="Обмен и возврат" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/catalog/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Каталог
            </Link>
            <meta itemProp="name" content="Каталог" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/license-department/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Стрелковые клубы и ЛРО
            </Link>
            <meta itemProp="name" content="Обмен и возврат" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
        </ul>
      </AccordionItem>
      <AccordionItem header="О компании" initialEntered data-sentry-element="AccordionItem" data-sentry-source-file="accordion-footer.tsx">
        <ul className={styles.accordionList} itemProp="about" itemScope itemType="https://schema.org/ItemList">
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/contacts/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Контакты
            </Link>
            <meta itemProp="name" content="Контакты" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/vacancy/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Вакансии
            </Link>
            <meta itemProp="name" content="Вакансии" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/brands/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Бренды
            </Link>
            <meta itemProp="name" content="Бренды" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/about/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              О компании
            </Link>
            <meta itemProp="name" content="О компании" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/policy/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Политика конф.
            </Link>
            <meta itemProp="name" content="Политика конфиденциальности" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
          <li className={styles.accordionItem} itemProp="itemListElement" itemScope itemType="https://schema.org/ItemList">
            <Link prefetch={false} href="/rules-for-posting-reviews/" className={styles.accordionLink} itemProp="url" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              Правила размещения отзывов
            </Link>
            <meta itemProp="name" content="Правила размещения отзывов" data-sentry-element="meta" data-sentry-source-file="accordion-footer.tsx" />
          </li>
        </ul>
      </AccordionItem>
      <div>
        <AccordionItem header="Контакты" initialEntered data-sentry-element="AccordionItem" data-sentry-source-file="accordion-footer.tsx">
          <ul className={styles.accordionList}>
            <li className={styles.accordionItem}>
              <Link prefetch={false} href="tel:88007008256" className={styles.accordionLink} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
                8 800 700-82-56
              </Link>
            </li>
            <li className={styles.accordionItem}>
              <Link prefetch={false} href="mailto:info@ohotaktiv.ru" className={styles.accordionLink} data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
                info@ohotaktiv.ru
              </Link>
            </li>
            <li className={accordionItemWorkClassnames}>
              Ежедневно с 7:00 - 22:00
            </li>
          </ul>
        </AccordionItem>
        <ul className={styles.accordionSocialList}>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://vk.com/ohotaktiv" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Вконтакте" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <VkIcon data-sentry-element="VkIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://t.me/ohotaktiv_official" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Телеграм" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <TgIcon data-sentry-element="TgIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://zen.yandex.ru/id/61d959165d11a84299ef6cec" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Яндекс.Дзен" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <DzenIcon data-sentry-element="DzenIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://ok.ru/ohotaktiv" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Одноклассники" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <OkIcon data-sentry-element="OkIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://www.youtube.com/channel/UCbeXH1XWDsSVL63-d0E43pw" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Ютуб" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <YoutubeIcon data-sentry-element="YoutubeIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
          <li className={styles.accordionSocialItem}>
            <Link prefetch={false} href="https://rutube.ru/channel/24618421/" className={styles.accordionSocialLink} target="_blank" rel="noopener nofollow" title="Подписаться на Рутуб" data-sentry-element="Link" data-sentry-source-file="accordion-footer.tsx">
              <RutubeIcon data-sentry-element="RutubeIcon" data-sentry-source-file="accordion-footer.tsx" />
            </Link>
          </li>
        </ul>
      </div>
    </ControlledAccordion>;
};