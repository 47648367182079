"use client";

import { CallbackPopup } from "@/components/popups/callback-popup/callback-popup";
import styles from "./header-info.module.scss";
import { FC, useState } from "react";
import Link from "next/link";
interface IHeaderInfo {
  customClassName?: string;
}
const HeaderInfo: FC<IHeaderInfo> = props => {
  const {
    customClassName
  } = props;
  const [show, setShowModal] = useState<boolean>(false);
  const handleShowModal = () => {
    setShowModal(!show);
  };
  const handleCloseModal = (arg: boolean) => {
    setShowModal(arg);
  };
  return <div className={`${styles.wrap} ${customClassName}`} data-sentry-component="HeaderInfo" data-sentry-source-file="header-info.tsx">
      <div className={styles.text_wrap}>
        <p className={styles.text}>7:00-22:00</p>
        <p className={styles.time}>по МСК</p>
      </div>
      <div className={styles.callback_container}>
        <Link prefetch={false} href="tel:88007008256" className={styles.link} onClick={() => ym(42989679, "reachGoal", "clickOnTel")} data-sentry-element="Link" data-sentry-source-file="header-info.tsx">
          8 800 700-82-56
        </Link>
        <button className={styles.callback} onClick={handleShowModal}>
          заказать звонок
        </button>
      </div>
      {show && <CallbackPopup isOpen={show} onClose={handleCloseModal} />}
    </div>;
};
export default HeaderInfo;